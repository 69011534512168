import { finished } from "stream";
import { M } from "vite/dist/node/types.d-aGj9QkWt";

export default {
  de: {
    "": "",
    app: "App",
    home: "Startseite",
    login: "Anmelden",
    addUser: "Benutzer hinzufügen",
    editUser: "Benutzer bearbeiten",
    logout: "Abmelden",
    user: "Benutzer",
    language: "Sprache",
    none: "Keine",
    role: "Rolle",
    roles: "Rollen",
    username: "Benutzername",
    email: "E-Mail",
    password: "Passwort",
    firstname: "Vorname",
    lastname: "Nachname",
    forgotPassword: "Passwort vergessen?",
    loginErrorTitle: "Login Fehler",
    userManagement: "Benutzerverwaltung",
    loginEmailError: "Für diesen Benutzernamen existiert kein Konto",
    loginPassword: "Falsches Passwort",
    loginError: "Falsche Zugangsdsaten",
    genericError:
      "Es ist ein Fehler aufgetreten, bitte versuchen sie es in ein paar Minuten erneut oder kontaktieren sie ihren Systemadministrator",
    search: "Suche",
    save: "Speichern",
    cancel: "Abbrechen",
    unchanged: "Unverändert",
    parameters: "Parameter",
    oneWeek: "Eine Woche",
    oneMonth: "Ein Monat",
    twoMonth: "Zwei Monate",
    threeMonth: "Drei Monate",
    sixMonth: "Sechs Monate",
    dragAndDopProperty:
      "Verwenden sie drag and drop auf einem Parameter um den Chart anzuzeigen",
    addFile: "Datei hinzufügen",
    name: "Name",
    type: "Typ",
    startDate: "Startdatum",
    endDate: "Enddatum",
    actions: "Aktionen",
    delete: "Löschen",
    confirmDelete: "Löschen bestätigen",
    error: "Fehler",
    status: "Status",
    close: "Schließen",
    correlation: "Korrelation",
    covariance: "Kovarianz",
    selectedDateRange: "Ausgewählter Zeitraum",
    min: "Min",
    max: "Max",
    mean: "Schnitt",
    tendency: "Tendenz",
    offset: "Offset",
    months: "Monate",
    days: "Tage",
    hours: "Stunden",
    minutes: "Minuten",
    seconds: "Sekunden",
    apply: "Anwenden",
    success: "Erfolgreich abgeschlossen",
    userCreated: "Der Benutzer wurde angelegt",
    changesSaved: "Die Änderungen wurden gespeichert",
    confirm: "Bestätigen",
    confirmDeleteUser:
      "Bitte bestätigen sie, dass sie den Benutzer {name} löschen wollen.",
    editAppSettings: "App Berechtigungen",
    accessibleBy: "Verfügbar für:",
    discard: "Verwerfen",
    editAsset: "Anlage oder Gerät bearbeiten",
    creatingAssetTemplate: "Anlagen und Geräte - Vorlage wird erstellt",
    createTemplate: "Modell erstellen",
    create: "Erstellen",
    addField: "Feld hinzufügen",
    fields: "Felder",
    description: "Beschreibung",
    text: "Text",
    select: "Auswahl",
    number: "Zahl",
    createField: "Feld erstellen",
    selectionOptions: "Auswahlmöglichkeiten",
    editSelectionOptions: "Auswahlmöglichkeiten bearbeiten",
    createSelectionOptions: "Auswahlmöglichkeiten erstellen",
    options: "Optionen",
    add: "Hinzufügen",
    templateManagement: "Modellverwaltung",
    assets: "Anlagen und Geräte",
    showInTable: "Sichtbar in der Tabelle",
    created: "Erstellt am",
    lastEdit: "Zuletzt bearbeitet",
    saveAndCreateAnother: "Speichern und weiteren Eintrag erstellen",
    entryAdded: "Eintrag hinzugefügt",
    title: "Titel",
    link: "Link",
    image: "Bild",
    appsAdmin: "Apps verwalten",
    createApp: "Create app",
    defaultLanguage: "Standardsprache",
    noRoles: "Keine zugewiesenen Rollen",
    noTwinTemplatesExist: "Es wurden bisher keine Schablonen angelegt.",
    properties: "Felder",
    assetManagement: "Anlagen und Geräte Management",
    yes: "Ja",
    no: "Nein",
    active: "Aktiv",
    hierarchy: "Hierarchie",
    back: "Zurück",
    changelog: "Änderungen",
    changelogDescription:
      "Änderungsprotokoll aller im Lager vorgenommener Ein- und Ausgänge.",
    changeType: "Änderungstyp",
    changedAt: "Geändert am",
    changedBy: "Geändert von",
    changedReason: "Änderungsgrund",
    assetCreated: "Anlage oder Gerät erstellt",
    propertyChanges: "Änderungen",
    id: "ID",
    reports: "Berichte",
    assetReports: "Anlagen und Geräte Berichte",
    assetChangelog: "Anlagen und Geräte Änderungen",
    assetReportsDescription:
      "Übersicht der Anlagen und Geräte und deren detaillierte Dokumentation gruppiert pro Typ",
    assetChangelogReportsDescription:
      "Liste der Änderungen an Anlagen und Geräten für den ausgewählten Zeitraum",
    show: "Anzeigen",
    export: "Export",
    filter: "Filter",
    edit: "Bearbeiten",
    documents: "Dokumente",
    addImages: "Hier klicken oder per „Drag & Drop“ Bilder hinzufügen.",
    dropFilesHere: "Dateien hier ablegen",
    uploadDocument: "Dokument hinzufügen",
    file: "Datei",
    selectFile: "Datei wählen",
    noFileSelected: "Keine Datei ausgewählt",
    editDocument: "Dokument bearbeiten",
    assetType: "Typ",
    firstName: "Vorname",
    lastName: "Nachname",
    manageInputValues: "Eingabemöglichkeiten verwalten",
    inputValues: "Eingabemöglichkeiten",
    instances: "Instanzen",
    mandatory: "Pflichtfeld",
    relation: "Relation",
    selectMultiple: "Mehrfachauswahl",
    noValues: "Aktuell sind keine Werte angelegt",
    noData: "Keine Daten",
    followingFieldsAreRequired:
      "Die folgenden Felder müssen ausgefüllt werden:",
    addRelation: "Relation hinzufügen",
    relations: "Relationen",
    category: "Kategorie",
    changesStartDate: "Änderungen von",
    changesEndDate: "Änderungen bis",
    clickOnShowToViewData: "Klicken sie auf {0} um Daten anzuzeigen",
    results: "Ergebnisse",
    addItem: "{item} hinzufügen",
    date: "Datum",
    year: "Jahr",
    yesNo: "Ja/Nein",
    pleaseEnterItem: "Bitte füllen sie das Feld '{item}' aus um fort zu fahren",
    itemAlreadyExists: "Ihre Eingabe existiert bereits",
    usedAsFilterIn: "Wird als filter verwendet für:",
    confirmDeleteValueList:
      "{valueList} wird als filter verwendet. Falls {valueList} gelöscht wird, werden die assoziierten Werte ebenfalls gelöscht.",
    selectFilterFirst: "Bitte wählen sie zuerst {filter} aus",
    propertyDeleteNotAvailableDueToFilteredValues:
      "{property} kann nicht gelöscht werden, da es als Filter für {filteredProperties} verwendet wird.\n\nErst wenn {property} nicht mehr als Filter verwendet wird kann es gelöscht werden.",
    spareParts: "Ersatzteile",
    settings: "Einstellungen",
    myAccount: "Mein Konto",
    myData: "Meine Daten",
    changePassword: "Passwort ändern",
    repeatPassword: "Passwort wiederholen",
    passwordChanged: "Passwort erfolgreich geändert.",
    passwordMinLength: "Das Password muss mindestens 8 Zeichen lang sein",
    passwordsDontMatch: "Die eingegebenen Passwörter stimmen nicht überein",
    articles: "Artikel",
    createArticle: "Artikel erstellen",
    singleArticles: "Einzelartikel",
    quantityArticle: "Mengenartikel",
    singleArticleDescription:
      "Artikel welche einzeln verwaltet werden. Z.B.: größere Ersatzteile für Maschinen.",
    quantityArticleDescription:
      "Artikel welche als Menge verwaltet werden, wie zum Beispiel Flüßigkeiten in Liter oder Schrauben in Kilogramm.",
    editable: "Bearbeitbar",

    handout: "Ausgabe",
    refill: "Eingang",
    transport: "Platzwechsel",
    inventory: "Inventur",

    amount: "Menge",
    comment: "Kommentar",
    warehouse: "Lager",
    consolidate: "Konsolidieren",
    consolidateText:
      "Es besteht bereits ein Artikel {article} in {warehouse}. Soll diesem Artikel die Menge hinzugefügt werden? Falls nicht wird ein identischer Artikel mit der entsprechenden Anzahl erstellt.",
    yesConsolidate:
      "Ja, die Menge soll zum bestehenden Artikel hinzugefügt werden.",
    createSingleArticle: "Einzelartikel erstellen",
    handoutToUser: "Ausgeben an",
    storageLocation: "Lagerort",

    articleDocumentedCorrectly:
      "Artikel ist in richtiger Menge am dokumentierten Lagerort vorhanden",
    articleInventoryDifference: "Abweichender Bestand",
    actualInventory: "Festgestellter Bestand",
    reason: "Grund",
    articleDoesNotExist: "Artikel existiert nicht",
    groups: "Gruppen",
    group: "Gruppe",
    accessRights: "Berechtigungen",
    accessRightsWarehouse: "Lagerberechtigungen",
    createGroup: "Gruppe erstellen",
    targetAndSourceAreSame:
      "Der aktuelle Lagerort ist derselbe wie der Ziellagerort",
    systemMisconfigured:
      "Das System ist falsch konfiguriert, bitte wenden sie sich an den Systemadministrator",
    editableOnCreate: "Bearbeitbar beim Erstellen",
    warehouseManager: "Lagermanager",
    warehouseInfo: "Lagerinfo",
    done: "Fertig",
    customId: "Custom id",
    all: "Alle",
    userCreatableEntriesForInputValues: "Dynamisches erstellen von Optionen",
    inventoryManagement: "Bestandsverwaltung",
    articleSearch: "Artikelsuche",
    addNomenclature: "Nomenklatur hinzufügen",
    createUser: "Benutzer erstellen",
    userExists:
      "Es existiert bereits ein Nutzer mit dieser E-Mail oder diesem Benutzernamen",

    // Ticket management
    myTickets: "Meine Tickets",
    ticketsAssignedToMe: "Mir zugewiesene Tickets",
    priority: "Priorität",
    dueDate: "Fälligkeitsdatum",
    createdBy: "Erstellt von",
    assignee: "Zugewiesen an",
    comments: "Kommentare",
    send: "Absenden",
    low: "Niedrig",
    medium: "Medium",
    high: "Hoch",
    loadPreviousComments: "Vorherige Kommentare laden",
    createTicket: "Ticket erstellen",
    files: "Dateien",
    noFiles: "Keine Dateien",
    noRelations: "Keine Relationen",
    requester: "Antragsteller",
    takeInProcess: "In Bearbeitung nehmen",
    reject: "Ablehnen",
    complete: "Abschliessen",
    validate: "Bestätigen",
    invalidate: "Ablehnen",
    longComment: "Details",
    draft: "Entwurf",
    createDraft: "Entwurf erstellen",
    submitDraft: "Entwurf einreichen",
    submit: "Einreichen",
    rejected: "Abgelehnt",
    new: "Neu",
    // Unfortunately the backend uses whitespace naming here
    "in process": "In Bearbeitung",
    completed: "Abgeschlossen",
    confirmed: "Bestätigt",
    plan: "Planen",
    planned: "Geplant",
    reopen: "Wiedereinreichen",
    myRequests: "Meine Anfragen",
    assignedToMe: "Mir Zugewiesen",
    assetManager: "Anlagen und Geräte manager",
    assetInfo: "Anlagen und Geräte info",
    maintenance: "Wartung",
    asset: "Anlagen und Geräte",
    location: "Ort",
    assetOrLocation: "Anlagen und Geräte oder ort",
    reportedBy: "Gemeldet von",
    damageDescription: "Schadensbeschreibung/-ursache",
    workCarriedOut: "Durchgeführte Arbeiten",
    ticketAdmin: "Ticket Administration",
    sopManagement: "Arbeitsanweisungen",
    enterWorkCarriedOutBeforeCompleting:
      "Bitte geben sie durchgeführte Arbeiten ein um das Ticket ab zu schließen.",
    createPlan: "Neuer Plan",
    repetition: "Wiederholung",
    weeks: "Wochen",
    years: "Jahre",
    createMaintenancePlan: "Wartungsplan erstellen",
    editMaintenancePlan: "Wartungsplan bearbeiten",
    leadDuration: "Vorlaufzeit",
    createSop: "Neue Arbeitsanweisung",
    sop: "Arbeitsanweisungen",
    maintenancePlans: "Wartungspläne",
    timespan: "Zeitspanne",
    firstActivation: "Erste Aktivierung",
    leadDurationDescription:
      "Zeitdauer für die Wartung. Falls zum Beispiel die Vorlaufzeit 1 Monat beträgt, wird das Ticket zu dieser Wartung 1 Monat vor dem Fälligkeitsdatum gestartet",
    ticketName: "Ticketname",
    ticketDescription: "Ticketbeschreibung",
    confirmDeleteMaintenancePlan:
      "Bitte bestätigen sie, dass sie den Wartungsplan {name} löschen möchten.",
    addSop: "Arbeitsanweisung hinzufügen",
    sops: "Arbeitsanweisungen",
    tasks: "Aufgaben",
    addMaterial: "Material hinzufügen",
    materialUse: "Materialverwendung",
    feedback: "Rückmeldung",
    completeTodoListItem: "Anweisung abschließen",
    itemsAvailable: "{items} verfügbar",
    createSopVersion: "Sop Version erstellen",
    manageSopVersions: "Sop Versionen verwalten",
    firstActivatedAt: "Erste Aktivierung",
    activation: "Aktivierung",
    confirmActivateVersion:
      "Sind sie sich sicher, dass sie die Version {1} {0} wollen?",
    activateSopVersion: "Arbeitsanweisung version aktivieren",
    deactivateSopVersion: "Arbeitsanweisung version deaktivieren",
    activate: "Aktivieren",
    deactivate: "Deaktivieren",
    editSop: "Arbeitsanweisung {name} bearbeiten",
    createTask: "Aufgabe erstellen",
    completionTracking: "Abschlussverfolgung",
    tasklist: "Aufgabenliste",
    task: "Aufgabe",
    sopNotEditable:
      "Diese Arbeitsanweisung ist nicht editierbar, da sie bereits verwendet wurde. Um die Arbeitsanweisung zu überarbeiten muss einen neue Version erstellt werden.",
    editTask: "Anweisung bearbeiten",
    version: "Version",
    versionActivated: "Version aktiviert",
    versionDeactivated: "Version deaktiviert",
    nOfMTasksCompleted: "({n}/{m}) Aufgaben erledigt",
    Permission: "Berechtigung",
    Permissions: "Berechtigungen",
    UniqueID: "Identifier",
    Accesstype: "Zugriffstyp",
    readAccess: "lesen",
    writeAccess: "schreiben",
    chooseDifferentArticle: "einen anderen Artikel auswählen",
    removeArticleFromWarehouse: "Diesen Artikel aus dem Lager entfernen",
    errorRemovingArticleFromWarehouse:
      "Der Artikel konnte nicht aus dem Lager entfernt werden, bitte navigieren sie zur Lagerverwaltung und dokumentieren sie dort den Warenausgang.",
    damageNotice: "Schadensmeldung",
    ticketType: "Ticketart",
    ticketNumber: "Ticket Nr.",
    qmdocuments: "Qualitätssicherungsvereinbarungsdokumente",
    customer: "Kunde",
    addDocument: "Dokument hinzufügen",
    inProcess: "In Bearbeitung",
    atCustomer: "Beim Kunden",
    finished: "Abgeschlossen",
    accepted: "Akzeptiert",
    content: "Inhalt",
    proposal: "Inhaltsvorschlag",
    dataSources: "Datenquellen",
    accept: "Akzeptieren",
    rewrite: "Umformulieren",
    filename: "Dateiname",
    itAssets: "IT Geräte",
    itAssetChangelog: "IT Geräte Änderungen",
    inspection: "Inspektion",
    itAssetManager: "IT Geräte Manager",
    itAssetInfo: "IT Geräte Informationen",
    maintenancePlanNotEditable:
      "Dieser Wartungsplan ist nicht editierbar, da er zur Zeit aktiv ist.",
    upload: "Upload",
    downloadTemplate: "Vorlage herunterladen",
    uploadCompleted: "Upload erfolgreich",
    maintenancePlanStartDateHasToBeInTheFutureToActivate:
      "Das Fälligkeitsdatum muss in der Zukunft liegen um den Plan zu aktivieren.",
    paragraphs: "Paragraphen",
    addParagraph: "Paragraph hinzufügen",
    processing: "Wird verarbeitet...",
    proposedContent: "Vorgeschlagener Inhalt",
    sentenceDetails: "Details",
    decline: "Ablehnen",
    addCustomContentProposal: "Eigenen Vorschlag einfügen",
    addContentAndAccept: "Vorschlag einfügen",
    useProposedContent: "Inhaltsvorschlag übernehmen",

    groupManagement: "Gruppenverwaltung",
    permissions: "Berechtigungen",
    permission: "Berechtigung",
    appRoles: "Anwendungsrollen",
    label: "Label",
    selection: "Auswahl",
    addPermission: "Berechtigung hinzufügen",
    clientId: "Client ID",
    howSatisfiedAreYouWithTheProposal:
      "Wie zufrieden sind sie mit dem Vorschlag?",
    unsatisfied: "Sehr Unzufrieden",
    notSatisfied: "Unzufrieden",
    mostlySatisfied: "Einigermaßen zufrieden",
    satisfied: "Zufrieden",
    verySatisfied: "Sehr zufrieden",
    editParagraph: "Paragraph bearbeiten",
    editParagraphWarning:
      "Wenn der Paragraph komplett überarbeitet wird gehen alle aktuellen Änderungen an einzelnen Sätzen verloren.",
    addRoles: "Anwendungsrollen hinzufügen",
    addCustomerResponse: "Antwort des Kunden einpflegen",
    editedVersion: "Überarbeitetete Version",
    similarSnippets: "Ähnliche Abschnitte",
    suggestion: "Empfehlung",
    document: "Dokument",
    customerResponse: "Antwort des Kunden",
    declined: "Abgelehnt",
    confirmDeleteItem: "Bitte bestätigen sie, dass sie {item} löschen wollen.",
    noSuggestion: "Kein Vorschlag",
    categories: "Kategorien",
    DE: "Deutsch",
    EN: "Englisch",
    createTranslation: "Übersetzung hinzufügen",
    createTopic: "Thema hinzufügen",
    editCategoryName: "Kategorie {name} bearbeiten",
    default: "Standard",
    topic: "Thema",
    categoryIsAlreadyInUse:
      "Die Kategorie kann nicht gelöscht werden, da sie bereits verwendet wird",
    warehouseTransfer: "Lagerwechsel",
    constraint: "Einschränkung",
    single: "Einfach",
    multiple: "Mehrfach",
    template: "Vorlage",
    calendar: "Kalender",
    duration: "Dauer",
    workDuration: "Arbeitsdauer",
    cloneTemplate: "Modell klonen",
    confirmCloneTemplate:
      "Sind sie sicher dass sie das Modell {name} klonen möchten?",
    ticketSearch: "Ticketsuche",
    onlyImagesAreAllowed: "Der Upload ist auf Bilder beschränkt",
    errorUpdatingPassword: "Das Passwort konnte nicht geändert werden.",
    technicalSpecificationsAndEquipmentEngineeringDocuments:
      "Technische Spezifikationen und Unterlagen zur Ausrüstung",
    equipmentLifecycleDocumentationAndMaintenanceRecords:
      "Wartungsdokumentation",
    moveToName: "Nach {name} verschieben",
    refresh: "Aktualisieren",
    responsibility: "Verantwortlich",

    saveMultipleInstances: "Mehrere Instanzen erstellen",
    chooseAmountOfInstances:
      "Wählen sie die Menge der Instanzen die erstellt werden sollen ",
    nOfmInstancesCreated: "{n} von {m} Instanzen erstellt",
  },

  en: {
    "": "",
    app: "App",
    home: "Startpage",
    login: "Login",
    logout: "Logout",
    user: "User",
    none: "None",
    language: "Language",
    role: "Role",
    roles: "Roles",
    username: "Username",
    email: "E-Mail",
    firstname: "First name",
    lastname: "Surname",
    addUser: "Add user",
    editUser: "Edit user",
    password: "Password",
    forgotPassword: "Forgot password?",
    loginErrorTitle: "Login Fehler",
    loginEmailError: "This username is not associated with any account",
    loginError: "Invalid user credentials",
    loginPassword: "Wrong password",
    userManagement: "User management",
    genericError:
      "An error occurred, please try again in a few minutes or contact your system administrator",
    search: "Search",
    save: "Save",
    cancel: "Cancel",
    unchanged: "Unchanged",
    parameters: "Parameters",
    oneWeek: "One week",
    oneMonth: "One month",
    twoMonth: "Two month",
    threeMonth: "Three month",
    sixMonth: "Six month",
    dragAndDopProperty: "Drag and drop a parameter to show its chart",
    addFile: "Add file",
    name: "Name",
    type: "Type",
    startDate: "Start date",
    endDate: "End date",
    actions: "Actions",
    delete: "Delete",
    confirmDelete: "Confirm Delete",
    error: "Fehler",
    status: "Status",
    close: "Close",
    correlation: "Correlation",
    covariance: "Covariance",
    selectedDateRange: "Selected date range",
    min: "Min",
    max: "Max",
    mean: "Mean",
    tendency: "Tendency",
    offset: "Offset",
    weeks: "Weeks",
    years: "Years",
    months: "Months",
    days: "Days",
    hours: "Hours",
    minutes: "Minutes",
    seconds: "Seconds",
    apply: "Apply",
    userCreated: "The user has been created",
    changesSaved: "Changes saved successfully",
    confirm: "Confirm",
    confirmDeleteUser: "Please confirm that you want to delete the user {name}",
    editAppSettings: "Edit app settings",
    accessibleBy: "Accessible by:",
    discard: "Discard",
    editAsset: "Edit asset",
    creatingAssetTemplate: "Creating asset template",
    createTemplate: "Create template",
    templateName: "Asset template name",
    create: "Create",
    addField: "Add field",

    text: "Text",
    select: "Selection",
    number: "Number",
    createField: "Create field",
    selectionOptions: "Options",
    editSelectionOptions: "Edit options",
    createSelectionOptions: "Create Options",
    options: "Options",
    add: "Add",
    templateManagement: "Template management",
    editAssetTemplate: "Edit asset {name} template",
    assets: "Assets",
    showInTable: "Visible in the table",
    created: "Created",
    lastEdit: "Last edit",
    saveAndCreateAnother: "Save and create another entry",
    entryAdded: "Entry added",
    title: "Title",
    link: "Link",
    image: "Image",
    appsAdmin: "Apps admin",
    createApp: "App erstellen",
    defaultLanguage: "Default language",
    noRoles: "No roles",
    noTwinTemplatesExist: "No templates have been created yet.",
    properties: "Properties",
    assetManagement: "Asset management",
    yes: "Yes",
    no: "No",
    active: "Active",
    hierarchy: "Hierarchy",
    back: "Back",
    changelog: "Changelog",
    changelogDescription:
      "A change log of all incoming and outgoing goods in the warehouse.",
    changeType: "Changetype",
    changedAt: "Changed at",
    changedBy: "Changed by",
    changedReason: "Change Reason",
    assetCreated: "Asset created",
    propertyChanges: "Changes",
    id: "ID",
    reports: "Reports",
    assetReports: "Asset Reports",
    assetChangelog: "Asset Changelogs",
    assetReportsDescription:
      "Overview of all assets and their documentation grouped by asset type",
    assetChangelogReportsDescription:
      "List of changes on asset documentation for the selected time range",
    show: "Show",
    export: "Export",
    filter: "Filter",
    edit: "Edit",
    documents: "Documents",
    addImages: "Use Drag & Drop or click here to add images",
    dropFilesHere: "Drop files here",
    uploadDocument: "Upload document",
    file: "File",
    selectFile: "Select file",
    noFileSelected: "No File Selected",
    editDocument: "Edit document",
    assetType: "Type",
    firstName: "First Name",
    lastName: "Last Name",
    manageInputValues: "Manage input values",
    inputValues: "Input values",
    instances: "Instances",
    mandatory: "Mandatory",
    relation: "Relation",
    selectMultiple: "Select multiple",
    noValues: "There are currently no values",
    noData: "No Data",
    followingFieldsAreRequired: "The following fields are required",
    category: "Category",
    changesStartDate: "Changes from",
    changesEndDate: "Changes to",
    clickOnShowToViewData: "Click on {0} to view the data",
    results: "Results",
    addRelation: "Add Relation",
    relations: "Relations",
    addItem: "Add {item}",
    date: "Date",
    year: "Year",
    yesNo: "Yes/No",
    pleaseEnterItem: "Please enter a {item} to continue.",
    itemAlreadyExists: "This entry already exists",
    usedAsFilterIn: "Used as filter for:",
    confirmDeleteValueList:
      "{valueList} might be used as a filter. If {valueList} is being deleted, all associated values will also be deleted.",
    selectFilterFirst: "Please select a {filter}",
    propertyDeleteNotAvailableDueToFilteredValues:
      "{property} can't be deleted since it is being used as a filter for {filteredProperties}.\n\nPlease delete these fields first",
    spareParts: "Spare parts",
    settings: "Settings",
    myAccount: "My account",
    myData: "Profile data",
    changePassword: "Change password",
    repeatPassword: "Repeat password",
    passwordChanged: "Password changed successfully.",
    passwordMinLength: "The password has to contain at least 8 characters",
    passwordsDontMatch: "The passwords you entered don't match",
    articles: "Articles",
    singleArticles: "Single articles",
    createArticle: "Create article",
    quantityArticle: "Quantity article",
    singleArticleDescription:
      "Articles that are managed separately in single articles, i.e. larger articles like replacement parts",
    quantityArticleDescription:
      "Articles that are managed by quantity i.e. liquids managed in litres or screws in weight",
    editable: "Editable",
    handout: "Handout",
    refill: "Refill",
    transport: "Transport",
    inventory: "Inventory",
    amount: "Amount",
    comment: "Comment",
    warehouse: "Warehouse",
    consolidate: "Consolidate",
    consolidateText:
      "An artile {article} already exists in {warehouse}. Should the amount be added to this article? If not an identical article will be created.",
    yesConsolidate: "Yes, add the items to the exisiting article",
    createSingleArticle: "Create single article",
    handoutToUser: "Handed out to",
    storageLocation: "Storage location",

    articleDocumentedCorrectly:
      "Article amount at the documented location is correct",
    articleInventoryDifference: "Amount differs",
    actualInventory: "Actual amount",
    reason: "Reason",
    articleDoesNotExist: "Article does not exist",
    group: "Group",
    groups: "Groups",
    accessRights: "Access rights",
    accessRightsWarehouse: "Acces rights warehouse",
    createGroup: "Create group",
    targetAndSourceAreSame: "Target and source location are the same",
    systemMisconfigured:
      "There is an error in the system configuration, please contact your system administrator",
    editableOnCreate: "Editable on create",
    warehouseManager: "Warehouse manager",
    warehouseInfo: "Warehous info",
    done: "Done",
    customId: "Custom id",
    all: "All",
    userCreatableEntriesForInputValues: "Users may dynamically add options",
    inventoryManagement: "Inventory management",
    articleSearch: "Article search",
    addNomenclature: "Add nomenclature",
    createUser: "Create user",
    userExists: "A user with that username or E-Mail already exists",
    // Ticket Management
    myTickets: "My Tickets",
    ticketsAssignedToMe: "Tickets assigned to me",
    priority: "Priority",
    dueDate: "Due date",
    createdBy: "Created by",
    assignee: "Assigned to",
    comments: "Comments",
    send: "Send",
    low: "Low",
    medium: "Medium",
    high: "High",
    loadPreviousComments: "Load previous comments",
    createTicket: "Create ticket",
    files: "Files",
    noFiles: "No files",
    noRelations: "No relations",
    requester: "Requester",
    takeInProcess: "Process",
    reject: "Reject",
    complete: "Complete",
    validate: "Validate",
    invalidate: "Invalidate",
    longComment: "Details",
    createDraft: "Create draft",
    submitDraft: "Submit draft",
    draft: "Draft",
    submit: "Submit",
    rejected: "Rejected",
    new: "New",
    "in process": "In process",
    completed: "Completed",
    confirmed: "Confirmed",
    plan: "Plan",
    planned: "Planned",
    reopen: "Reopen",
    myRequests: "My requests",
    assignedToMe: "Assigned to me",
    assetManager: "Asset manager",
    assetInfo: "Asset info",
    maintenance: "Maintenance",
    asset: "Asset",
    location: "location",
    assetOrLocation: "Asset or location",
    reportedBy: "Reported by",
    damageDescription: "Damage description",
    workCarriedOut: "Work done",
    ticketAdmin: "Ticket admin",
    sopManagement: "SOP Management",
    enterWorkCarriedOutBeforeCompleting:
      "Please enter the work carried out before completing the ticket.",
    createPlan: "Create plan",
    repetition: "Repetition",
    createMaintenancePlan: "Create maintenance plan",
    editMaintenancePlan: "Edit maintenance plan",
    leadDuration: "Lead duration",
    sop: "SOP",
    createSop: "Create SOP",
    maintenancePlans: "Maintenance plans",
    timespan: "Timespan",
    firstActivation: "First activation",
    leadDurationDescription:
      "Duration before the due date. I.e. if this is set to 1 month, the ticket for this maintenance process will be create 1 month before the due date.",
    ticketName: "Ticket name",
    ticketDescription: "Ticket description",
    confirmDeleteMaintenancePlan:
      "Please confirm that you want to delete the maintenance plan {name}",
    addSop: "Add SOP",
    sops: "SOPs",
    tasks: "Tasks",
    addMaterial: "Add material",
    materialUse: "Material use",
    feedback: "Feedback",
    completeTodoListItem: "Complete todo list item",
    itemsAvailable: "{items} available",
    createSopVersion: "Add sop version",
    manageSopVersions: "Manage sop versions",
    firstActivatedAt: "First activation",
    activation: "Activation",
    confirmActivateVersion: "Please confirm you want to {0} {1}",

    activateSopVersion: "Activate sop version",
    deactivateSopVersion: "Deactivate sop version",
    activate: "Activate",
    deactivate: "Deactivate",
    editSop: "Edit sop {name}",
    completionTracking: "Completion tracking",
    createTask: "Create task",
    tasklist: "Task list",
    task: "Task",
    sopNotEditable:
      "This sop is not editable, since it has already been used. To edit this sop, create a new version.",
    editTask: "Edit task",
    version: "Version",
    versionActivated: "Version activated",
    versionDeactivated: "Version deactivated",
    nOfMTasksCompleted: "({n}/{m}) Tasks completed",
    Permission: "Permission",
    Permissions: "Permissions",
    UniqueID: "Unique ID",
    Accesstype: "Access type",
    writeAccess: "write",
    readAccess: "read",
    chooseDifferentArticle: "choose different article",
    removeArticleFromWarehouse: "Remove this article from the warehouse",
    errorRemovingArticleFromWarehouse:
      "The article could not be removed from the warehouse, please naigate to the warehouse and document the handout.",
    damageNotice: "Damage report",
    ticketType: "Ticket type",
    ticketNumber: "Ticket No.",
    qmdocuments: "Quality management documents",
    customer: "Customer",
    addDocument: "Add document",
    inProcess: "In process",
    atCustomer: "At customer",
    finished: "finished",
    accepted: "Accepted",
    content: "Content",
    proposal: "Proposed content",
    dataSources: "Data sources",
    accept: "Accept",
    rewrite: "Rewrite",
    filename: "Filename",
    itAssets: "IT assets",
    itAssetChangelog: "IT asset changelog",
    inspection: "Inspection",
    itAssetManager: "IT asset manager",
    itAssetInfo: "IT asset informationen",
    maintenancePlanNotEditable:
      "This maintenance plan is currently not editable since it is active.",
    upload: "Upload",
    downloadTemplate: "Vorlage herunterladen",
    uploadCompleted: "Upload completed",
    maintenancePlanStartDateHasToBeInTheFutureToActivate:
      "The due date has to be in the future in order to activate a plan",
    paragraphs: "Paragraphs",
    addParagraph: "Add paragraph",
    processing: "Processing...",
    proposedContent: "Proposed content",
    sentenceDetails: "Details",
    addCustomContentProposal: "Add custom content",
    addContentAndAccept: "Add content proposal",
    useProposedContent: "Use proposal",

    groupManagement: "Group Management",
    permissions: "Permissions",
    permission: "Permission",
    appRoles: "App Roles",
    label: "Label",
    selection: "Selection",
    addPermission: "Add Permission",
    clientId: "Client ID",
    howSatisfiedAreYouWithTheProposal:
      "How satisfied are you with the proposal?",
    unsatisfied: "Unsatisfied",
    notSatisfied: "Not Satisfied",
    mostlySatisfied: "Mostly satisfied",
    satisfied: "Satisfied",
    verySatisfied: "Very satisfied",
    editParagraph: "Edit Paragraph",
    editParagraphWarning:
      "When editing the whole paragraph, all current changes to sentences are lost.",
    addRoles: "Add roles",
    addCustomerResponse: "Add customer response",
    editedVersion: "Edited version",
    similarSnippets: "Similar snippets",
    suggestion: "Suggestion",
    document: "Document",
    customerResponse: "Customer response",
    declined: "Declined",
    confirmDeleteItem: "Please confirm you want to delete {item}.",
    noSuggestion: "No suggestion",
    categories: "Categories",
    DE: "German",
    EN: "English",
    createTranslation: "Create translation",
    createTopic: "Create topic",
    editCategoryName: "Edit category {name}",
    default: "Default",
    topic: "Topic",
    categoryIsAlreadyInUse:
      "The category can't be deleted since it is already being used",
    warehouseTransfer: "Warehouse transfer",
    constraint: "Constraint",
    single: "Single",
    multiple: "Multiple",
    template: "Template",
    calendar: "Calendar",
    duration: "Duration",
    workDuration: "Work duration",
    cloneTemplate: "Clone template",
    confirmCloneTemplate: "Are you sure you want to clone the {name} template?",
    ticketSearch: "Ticket search",
    onlyImagesAreAllowed: "Upload is restricted to images",
    errorUpdatingPassword: "Error updating the password",
    technicalSpecificationsAndEquipmentEngineeringDocuments:
      "Technical Specifications and Equipment Engineering Documents",
    equipmentLifecycleDocumentationAndMaintenanceRecords:
      "Equipment Lifecycle Documentation and Maintenance Records",
    moveToName: "Move to {name}",
    refresh: "Refresh",
    responsibility: "Responsibility",
    saveMultipleInstances: "Create multiple instances",
    chooseAmountOfInstances:
      "Choose the amount of instances you want to create ",
    nOfmInstancesCreated: "{n} of {m} instances created",
  },
};

// Merge two message objects, the second one will override message from the first one
export function mergeMessages(messages1: Object, messages2: Object) {
  const result = {};
  [...new Set([...Object.keys(messages1), ...Object.keys(messages2)])].forEach(
    (langCode) =>
      (result[langCode] = {
        ...messages1[langCode],
        ...messages2[langCode],
      }),
  );
  return result;
}
