export const ticketManagementPrefix = "tm.ticket.maintenance.*";

export const clientId = "smx-maintenance";

export const materialConsumptionId = "materialConsumption";

export const filterKeys = {
  responsibility: "maintenanceResponsibility",
};

export const statuses = {
  draft: "draft",
  new: "new",
  planned: "planned",
  inProcess: "in process",
  completed: "completed",
  rejected: "rejected",
  confirmed: "confirmed",
};

export const ticketProps = {
  assignee: "assignee",
  requester: "requester",
  status: "status",
  dueDate: "dueDate",
  startDate: "startDate",
  priority: "priority",
  createdBy: "createdBy",
  workCarriedOut: "workCarriedOut",
  asset: "asset",
  area: "area",
  maintenanceRequestType: "maintenanceRequestType",
  actualMaintenanceTime: "actualMaintenanceTime",
  ticketNumber: "ticketNumber",
  responsibility: "responsibility",
};
