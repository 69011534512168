import { ref, computed, type Ref, watch } from "vue";
import { defineStore } from "pinia";
import type { DecodedToken, Token } from "../models/auth";
import * as authApi from "../services/auth";
import type { LoadState } from "../models/state";
import { refreshToken, TokenResponse } from "../services/auth";
import { getActivePinia } from "pinia";

const rawUser = localStorage.getItem("user");
const decodedUser = rawUser ? JSON.parse(rawUser) : null;

import {
  stateInitial,
  stateLoading,
  stateLoaded,
  stateError,
} from "../models/state";
import { update } from "lodash";
import { useRouter } from "vue-router";
import { decode } from "querystring";

export const useAuthStore = defineStore("auth", () => {
  const provider = ref<"KeyCloak" | "eqAccount">("eqAccount");

  const keyCloakConfig = {
    client_id: "smx-portal",
    realmName: "inctec-portal",
  };

  function setRealmName(name: string) {
    keyCloakConfig.realmName = name;
  }

  const tokenState: Ref<Token & { loadingState: LoadState<{}> }> =
    decodedUser && decodedUser.access && decodedUser.refresh
      ? ref(decodedUser)
      : ref({
          refresh: null,
          access: null,
          loadingState: stateInitial,
        });

  const hasBeenLoggedIn = ref(tokenState.value.access !== null);
  watch(tokenState, () => {
    if (tokenState.value.access !== null) {
      hasBeenLoggedIn.value = true;
    }
  });

  async function login({
    username,
    password,
    termsAndConditions,
  }: {
    username: string;
    password: string;
    termsAndConditions: boolean;
  }) {
    tokenState.value.loadingState = stateLoading;
    if (provider.value === "KeyCloak") {
      await authApi
        .loginKeyCloak(
          {
            client_id: keyCloakConfig.client_id,
            grant_type: "password",
            password,
            username,
          },
          keyCloakConfig.realmName,
        )
        .then(
          (response) => {
            updateToken({
              access: response.data.access_token,
              refresh: response.data.refresh_token,
            });
            return response;
          },
          (error) => {
            tokenState.value.loadingState = stateError;
            throw error;
          },
        );
    } else {
      await authApi.login(username, password, termsAndConditions).then(
        (response) => {
          updateToken({
            access: response.data.token,
            refresh: response.data.refresh,
          });
          return response;
        },
        (error) => {
          tokenState.value.loadingState = stateError;
          throw error;
        },
      );
    }
  }

  const router = useRouter();

  function logout() {
    tokenState.value.access = null;
    tokenState.value.refresh = null;
    localStorage.clear();
    router.push({ name: "login" });
    // Reset all stores to make sure that store caches are removed
    // @ts-ignore
    // getActivePinia()._s.forEach(store => store.$dispose());
    if (hasBeenLoggedIn.value) {
      window.location.reload();
    }
  }

  function updateToken(token: Token) {
    tokenState.value = { ...token, loadingState: stateLoaded };
    document.cookie = `token=${token.access};path=/`;
    localStorage.setItem("user", JSON.stringify(tokenState.value));
  }

  const decodedToken = computed<DecodedToken>(() => {
    const token = tokenState.value.access;
    if (token === null) {
      return null;
    }
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join(""),
    );

    return JSON.parse(jsonPayload);
  });

  const roles = computed(() => {
    return decodedToken.value?.realm_access.roles;
  });

  async function refreshToken() {
    try {
      const response =
        provider.value === "KeyCloak"
          ? await authApi
              .refreshKeyCloak(
                tokenState.value.refresh,
                keyCloakConfig.client_id,
                keyCloakConfig.realmName,
              )
              .then(({ data }) => ({
                access: data.access_token,
                refresh: data.refresh_token,
              }))
          : await authApi
              .refreshToken(tokenState.value.refresh)
              .then(({ data }) => ({
                access: data.token,
                refresh: data.refresh,
              }));
      updateToken(response);
      return response;
    } catch (e) {
      logout();
      throw e;
    }
  }

  const rolesByClientId = computed<Record<string, { roles: string[] }>>(() => {
    return decodedToken.value?.resource_access ?? {};
  });

  const userId = computed(() => {
    return decodedToken.value?.sub;
  });

  return {
    setRealmName,
    login,
    logout,
    updateToken,
    refreshToken,
    tokenState,
    provider,
    roles,
    decodedToken,
    rolesByClientId,
    userId,
  };
});
